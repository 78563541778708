import { createTheme } from "@mui/material/styles";

export const dark = createTheme({
  palette: {
    mode: "dark",
  },
  typography: {
    fontFamily: ['"Roboto Mono", monospace'],
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontSize: 16,
    fontWeightMedium: 500,
    h1: {
      fontFamily: '"Exo 2", sans-serif',
      fontSize: "30.88px",
      textTransform: "uppercase",
      letterSpacing: "0.25px",
      lineHeight: "41.07px",
      "@media only screen and (min-width:768px)": {
        fontSize: "67px",
        lineHeight: "87.66px",
        letterSpacing: "0.54px",
      },
    },
    h2: {
      fontSize: "28px",
      fontFamily: '"Exo 2", sans-serif',
      letterSpacing: "0.23px",
      lineHeight: "39.66px",
      "@media only screen and (min-width:768px)": {
        fontSize: "47px",
        letterSpacing: "0.38px",
        lineHeight: "64.66px",
      },
    },
    h3: {
      fontSize: "28px",
      fontFamily: '"Exo 2", sans-serif',
      letterSpacing: "0.23px",
      lineHeight: "39.66px",
      "@media only screen and (min-width:768px)": {
        fontSize: "43px",
        letterSpacing: "0.35px",
        lineHeight: "54px",
      },
    },
    h4: {
      fontSize: "32px",
      fontFamily: '"Exo 2", sans-serif',
      letterSpacing: "0.26px",
      lineHeight: "39px",
      "@media only screen and (min-width:768px)": {
        fontSize: "37px",
        letterSpacing: "0.35px",
        lineHeight: "54px",
      },
    },
    h5: {
      fontSize: "27px",
      letterSpacing: "0.22px",
      lineHeight: "33px",
      fontFamily: '"Exo 2", sans-serif',
      "@media only screen and (min-width:768px)": {
        fontSize: "32px",
        letterSpacing: "0.26px",
        lineHeight: "39px",
      },
    },
    h6: {
      fontSize: "18px",
      fontFamily: '"Exo 2", sans-serif',
      letterSpacing: "0.1px",
      lineHeight: "39px",
    },
    subtitle1: {
      fontFamily: ['"Roboto Mono", monospace'],
      fontSize: "10px",
      fontWeight: "bold",
      letterSpacing: "3.33px",
      lineHeight: "39.24px",
      "@media only screen and (min-width:768px)": {
        fontSize: "15px",
        letterSpacing: "5px",
      },
    },
    subtitle2: {
      fontFamily: ['"Roboto Mono", monospace'],
      fontSize: "14px",
      opacity: "0.55",
      letterSpacing: "0",
      LineHeight: "39.24px",
      "@media only screen and (min-width:768px)": {
        fontSize: "18px",
      },
    },
    body1: {
      fontFamily: ['"Roboto Mono", monospace'],
      fontSize: "17px",
      letterSpacing: 0,
      lineHeight: "27.24px",
      "@media only screen and (min-width:768px)": {
        lineHeight: "29.24px",
      },
    },
    body2: {
      fontFamily: ['"Roboto Mono", monospace'],
      fontSize: "16px",
      letterSpacing: 0,
      lineHeight: "27.24px",
      "@media only screen and (min-width:768px)": {
        lineHeight: "29.24px",
      },
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          height: 40,
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        divider: {
          borderBottom: "1px dashed",
          paddingTop: 20,
          paddingBottom: 20,
        },
      },
    },
  },
});
