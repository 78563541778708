import React, { useState } from "react";
import { themeCreator } from "../theme/base";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

export const ThemeContext = React.createContext((themeName) => {});
const ThemeProvider = (props) => {
  // Read current theme from localStorage or maybe from an api

  const getInitialTheme =
    typeof window !== `undefined`
      ? localStorage.getItem("appTheme") || "dark"
      : "light";

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(getInitialTheme);
  // Get the theme object by theme name
  const theme = themeCreator(themeName);
  const setThemeName = (themeName) => {
    localStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };

  //console.log("THEME", { themeName });

  return (
    <ThemeContext.Provider value={{ themeName, setThemeName }}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
export default ThemeProvider;
